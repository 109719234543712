export const ENDPOINTS = {
  automations: "/api/automations/",
  signUp: "/api/sign-up",
  login: "/api/login",
  configuration: "/api/configuration/",
  automation: "/api/automations/",
  checkoutSession: "/api/create-checkout-session",
  subscriptionStatus: "/api/subscription-status",
};

export const PATHS = {
  home: "/",
  signUp: "/sign-up",
  signIn: "/sign-in",
  configuration: "/configuration",
  automation: "automation/:automationId",
  payment: "/payment",
};

export const TOKEN_KEY = "token";

export let STRIPE_PUBLISHABLE_KEY: string;

if (process.env.REACT_APP_VERCEL_ENV === "production") {
  STRIPE_PUBLISHABLE_KEY =
    "pk_live_51LucAuHy7GRDhtoohthTU8JkDV4DmQQxgLMU9kDhg0HqRu91Mgxa0K7bluBAhnWpYdeGD8paRIFYzfayjUBlJYxy00iAdR8KMP";
} else {
  STRIPE_PUBLISHABLE_KEY =
    "pk_test_51LucAuHy7GRDhtooszrsLAdiUJUJFprnPEMir52jxEIsxqpKAqOlLOYCUA3iboTdl2p0Qn7UJLCMAX0817IPs5Ju00dKJT73p7";
}
