import React, { useEffect, useState } from "react";
import {
  AlertStatus,
  AutomationResponse,
  EditAutomationPayload,
} from "../types";
import { updateAutomation, fetchAutomation, toggleAutomation } from "../api";

import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import { Card, Chip, FormControl, Grid, TextField, Link } from "@mui/material";
import {
  makeRange,
  parseAutomationChip,
  parseAutomationStatus,
} from "../utils";
import ConfigurationSnackbar from "../components/ConfigurationSnackbar";

const Automation = () => {
  let { automationId } = useParams();
  const { control, getValues, formState, reset } =
    useForm<EditAutomationPayload>({
      defaultValues: {
        smsTemplateId: "",
        phoneNumberId: "",
        frequency: "",
        messagesPerTrigger: "",
      },
    });

  const [automationResponse, setAutomationResponse] =
    useState<null | AutomationResponse>(null);

  const [open, setOpen] = React.useState(false);
  const [status, setStatus] = React.useState<"" | AlertStatus>("");

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
    setStatus("");
  };

  const resetData = (data: AutomationResponse) => {
    reset({
      smsTemplateId: data.automation.smsTemplateId || "",
      phoneNumberId: data.automation.phoneNumberId || "",
      frequency: data.automation.frequency.toString() || "",
      messagesPerTrigger: data.automation.messagesPerTrigger.toString() || "",
    });
  };

  useEffect(() => {
    if (automationId) {
      fetchAutomation(automationId)
        .then((response) => response.json())
        .then((data: AutomationResponse) => {
          setAutomationResponse(data);
          reset({
            smsTemplateId: data.automation.smsTemplateId || "",
            phoneNumberId: data.automation.phoneNumberId || "",
            frequency: data.automation.frequency.toString() || "",
            messagesPerTrigger:
              data.automation.messagesPerTrigger.toString() || "",
          });
        });
    }
  }, [automationId, reset]);

  if (!automationResponse) {
    return <></>;
  }

  const { automation, smsNumbers, smsTemplates } = automationResponse;

  const update = () => {
    const data = getValues();
    updateAutomation(automation.smartViewId, data).then((response) => {
      if (response.ok) {
        response.json().then((data: AutomationResponse) => {
          setAutomationResponse(data);
          resetData(data);
          setOpen(true);
          setStatus(AlertStatus.SUCCESS);
        });
      } else {
        setOpen(true);
        setStatus(AlertStatus.ERROR);
      }
    });
  };

  const toggle = () => {
    toggleAutomation(automation.smartViewId, automation.active).then(
      (response) => {
        if (response.ok) {
          response.json().then((data: AutomationResponse) => {
            setAutomationResponse(data);
            setOpen(true);
            setStatus(AlertStatus.SUCCESS);
          });
        } else {
          setOpen(true);
          setStatus(AlertStatus.ERROR);
        }
      }
    );
  };

  return (
    <>
      <Container maxWidth="sm">
        <Box
          sx={{
            marginTop: 8,
            flexDirection: "column",
          }}
        >
          <Grid container direction="column" alignItems="center">
            <Grid item>
              <Typography align="center" component="h1" variant="h5">
                {automation.name}{" "}
                <Chip
                  sx={{
                    pl: "4px",
                    pr: "4px",
                    backgroundColor: parseAutomationChip(automation.active),
                    color: "#fff",
                  }}
                  size="small"
                  label={parseAutomationStatus(automation.active)}
                ></Chip>
              </Typography>
            </Grid>
            <Grid item paddingTop={2}>
              <Link
                href={`https://app.close.com/leads/${automation.smartViewId}/`}
                target="_blank"
              >
                View in Close
              </Link>
            </Grid>
          </Grid>
          <Card
            variant="outlined"
            sx={{
              p: 0,
            }}
          ></Card>
          <Grid
            container
            spacing={0}
            sx={{
              mb: 2,
            }}
          >
            <Grid item lg={12} md={12} sm={12}>
              <FormControl fullWidth={true}>
                <Controller
                  control={control}
                  name="smsTemplateId"
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      select
                      variant="outlined"
                      label="SMS Template"
                      onChange={onChange}
                      value={value}
                      sx={{
                        mb: 2,
                      }}
                    >
                      {smsTemplates.map((smsTemplate) => {
                        return (
                          <MenuItem key={smsTemplate.id} value={smsTemplate.id}>
                            {smsTemplate.name}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item lg={12} md={12} sm={12}>
              <FormControl fullWidth={true}>
                <Controller
                  control={control}
                  name="phoneNumberId"
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      select
                      variant="outlined"
                      label="Phone Number"
                      onChange={onChange}
                      value={value}
                      sx={{
                        mb: 2,
                      }}
                    >
                      {smsNumbers.map((phoneNumber) => {
                        return (
                          <MenuItem key={phoneNumber.id} value={phoneNumber.id}>
                            {phoneNumber.name}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  )}
                />
              </FormControl>
            </Grid>

            <Grid item lg={12} md={12} sm={12}>
              <FormControl fullWidth={true}>
                <Controller
                  control={control}
                  name="frequency"
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      select
                      variant="outlined"
                      label="Frequency"
                      onChange={onChange}
                      value={value}
                      sx={{
                        mb: 2,
                      }}
                    >
                      {makeRange(1, 59).map((freq) => {
                        return (
                          <MenuItem key={freq} value={freq.toString()}>
                            {freq === 1 ? `${freq} Minute` : `${freq} Minutes`}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item lg={12} md={12} sm={12}>
              <FormControl fullWidth={true}>
                <Controller
                  control={control}
                  name="messagesPerTrigger"
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      select
                      variant="outlined"
                      label="Messages Per Trigger"
                      onChange={onChange}
                      value={value}
                      sx={{
                        mb: 2,
                      }}
                    >
                      {makeRange(1, 4).map((mpt) => {
                        return (
                          <MenuItem key={mpt} value={mpt.toString()}>
                            {mpt === 0
                              ? `${mpt} Message Per Trigger`
                              : `${mpt} Messages Per Trigger`}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Box alignItems="center" justifyContent="center" display="flex">
            <Button onClick={update} variant="contained" sx={{ mt: 3, mb: 2 }}>
              Save
            </Button>
          </Box>
          {automation.valid && (
            <Box alignItems="center" justifyContent="center" display="flex">
              <Button
                onClick={toggle}
                disabled={formState.isDirty}
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                {automation.active ? "Stop" : "Start"}
              </Button>
            </Box>
          )}
        </Box>
      </Container>

      <ConfigurationSnackbar
        open={open}
        handleClose={handleClose}
        status={status}
        item={"Automation"}
      />
    </>
  );
};

export default Automation;
