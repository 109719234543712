import React from "react";
import { Link, NavLink } from "react-router-dom";
// @ts-ignore
import { SidebarWidth } from "../../../assets/global/Theme-variable";
import LogoIcon from "../Logo/LogoIcon";
import { useLocation } from "react-router-dom";
import {
  useMediaQuery,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Drawer,
} from "@mui/material";
import MenuItems from "./data";
import { Person } from "@mui/icons-material";
import { PATHS } from "../../../constants";
import { deleteToken, isAuthenticated } from "../../../utils";
import { useNavigate } from "react-router-dom";

interface Props {
  isSidebarOpen: boolean;
  isMobileSidebarOpen: boolean;
  onSidebarClose: () => void;
}

const Sidebar = (props: Props) => {
  const [open, setOpen] = React.useState<boolean | number>(true);
  const { pathname } = useLocation();
  const pathDirect = pathname;
  const lgUp = useMediaQuery((theme: any) => theme.breakpoints.up("lg"));

  const navigate = useNavigate();

  const handleLogout = () => {
    deleteToken();
    navigate(PATHS.signIn);
  };

  const handleClick = (index: number) => {
    if (open === index) {
      setOpen((prevopen) => !prevopen);
    } else {
      setOpen(index);
    }
  };

  const authenticatedSidebarContent = (
    <Box sx={{ p: 3, height: "calc(100vh - 40px)" }}>
      <Link to="/">
        <Box sx={{ display: "flex", alignItems: "Center" }}>
          <LogoIcon />
        </Box>
      </Link>

      <Box>
        <List
          sx={{
            mt: 4,
          }}
        >
          {MenuItems.map((item, index) => {
            return (
              <List component="li" disablePadding key={item.title}>
                <ListItem
                  onClick={() => handleClick(index)}
                  button
                  component={NavLink}
                  to={item.href}
                  selected={pathDirect === item.href}
                  sx={{
                    mb: 1,
                    ...(pathDirect === item.href && {
                      color: "white",
                      backgroundColor: (theme) =>
                        `${theme.palette.primary.main}!important`,
                    }),
                  }}
                >
                  <ListItemIcon
                    sx={{
                      ...(pathDirect === item.href && { color: "white" }),
                    }}
                  >
                    <item.icon width="20" height="20" />
                  </ListItemIcon>
                  <ListItemText>{item.title}</ListItemText>
                </ListItem>
              </List>
            );
          })}
        </List>
        <List>
          <ListItem
            onClick={handleLogout}
            button
            component={NavLink}
            to={PATHS.signIn}
          >
            <ListItemIcon>
              <Person width="20" height="20" />
            </ListItemIcon>
            <ListItemText>Log Out</ListItemText>
          </ListItem>
        </List>
      </Box>
    </Box>
  );

  const unAuthenticatedSidebarContent = (
    <Box sx={{ p: 3, height: "calc(100vh - 40px)" }}>
      <Link to="/">
        <Box sx={{ display: "flex", alignItems: "Center" }}>
          <LogoIcon />
        </Box>
      </Link>
    </Box>
  );
  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open={props.isSidebarOpen}
        variant="persistent"
        PaperProps={{
          sx: {
            width: SidebarWidth,
          },
        }}
      >
        {isAuthenticated()
          ? authenticatedSidebarContent
          : unAuthenticatedSidebarContent}
      </Drawer>
    );
  }
  return (
    <Drawer
      anchor="left"
      open={props.isMobileSidebarOpen}
      onClose={props.onSidebarClose}
      PaperProps={{
        sx: {
          width: SidebarWidth,
        },
      }}
      variant="temporary"
    >
      {isAuthenticated()
        ? authenticatedSidebarContent
        : unAuthenticatedSidebarContent}
    </Drawer>
  );
};

export default Sidebar;
