export type UserSignUp = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
};

export type UserSignIn = {
  email: string;
  password: string;
};

export type UserAuthResponse = {
  token: string;
};

export type AutomationType = {
  active: boolean;
  id: string;
  name: string;
  hasAutomation: boolean;
};

export type StripeStatus = {
  subscriptionActive: boolean | undefined;
};

export type CloseData = {
  closeApiKey: string | undefined;
};

export type ConfigurationResponse = {
  closeApiKey: string;
};

export type ConfigurationRequest = ConfigurationResponse;

export type EditAutomationPayload = {
  smsTemplateId: string;
  phoneNumberId: string;
  frequency: string;
  messagesPerTrigger: string;
};

export type AutomationResponse = {
  automation: Automation;
  smsNumbers: { name: string; id: string }[];
  smsTemplates: { name: string; id: string }[];
};

export type AutomationsResponse = {
  automations: AutomationType[];
  stripeStatus: StripeStatus;
  closeData: CloseData;
  userData: UserData;
};

export type UserData = {
  id: number;
  email: string;
};

export type Automation = {
  active: boolean;
  closeUserId: string;
  email: string;
  frequency: string;
  messagesPerTrigger: string;
  name: string;
  phoneNumberId: string;
  smartViewId: string;
  timestamp: string;
  valid: boolean;
  smsTemplateId: string;
};

export enum AlertStatus {
  SUCCESS = "success",
  ERROR = "error",
  INFO = "info",
  WARNING = "warning",
}

export enum RunningStatus {
  Running = "Running",
  Stopped = "Stopped",
}
