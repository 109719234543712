import React, { useEffect, useState } from "react";
import { createCheckoutSession, fetchSubscriptionStatus } from "../api";

import { loadStripe, Stripe } from "@stripe/stripe-js";
import { STRIPE_PUBLISHABLE_KEY } from "../constants";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

export default function Payment() {
  const [stripe, setStripe] = useState<Stripe | null>(null);
  const [subscriptionStatus, setSubscriptionStatus] = useState<null | boolean>(
    null
  );
  const [isloaded, setIsLoaded] = useState<boolean>(false);

  const onSubmit = () => {
    if (stripe) {
      createCheckoutSession()
        .then((response) => response.json())
        .then((data: { redirectUrl: string; sessionId: string }) =>
          stripe.redirectToCheckout({ sessionId: data.sessionId })
        );
    }
  };

  useEffect(() => {
    fetchSubscriptionStatus()
      .then((response) => response.json())
      .then((data: { subscriptionActive: boolean }) => {
        setSubscriptionStatus(data.subscriptionActive);
        setIsLoaded(true);
      });
  }, []);

  useEffect(() => {
    loadStripe(STRIPE_PUBLISHABLE_KEY).then((data: any) => {
      setStripe(data);
    });
  }, []);

  if (!isloaded) {
    return <></>;
  }

  let component;
  if (!subscriptionStatus) {
    component = (
      <Button variant={"contained"} onClick={onSubmit}>
        Subscribe to Triton SMS
      </Button>
    );
  } else {
    component = (
      <Typography>
        Your Subscription is active. Please contact support at
        contact@tritonreicollective.com for help.
      </Typography>
    );
  }

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          marginTop: 8,
          flexDirection: "column",
        }}
      >
        {component}
      </Box>
    </Container>
  );
}
