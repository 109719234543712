import {
  Button,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import { AutomationType } from "../types";
import { parseAutomationChip, parseAutomationStatus } from "../utils";
import { useNavigate } from "react-router-dom";

const AutomationTable = ({
  automations,
}: {
  automations: AutomationType[];
}) => {
  const AutomationRow = ({ automation }: { automation: AutomationType }) => {
    const navigate = useNavigate();

    const viewAutomation = () => {
      navigate(`/automation/${automation.id}`);
    };

    return (
      <TableRow>
        <TableCell>
          <Typography
            sx={{
              fontSize: "15px",
              fontWeight: "500",
            }}
          >
            {automation.name}
          </Typography>
        </TableCell>
        <TableCell>
          <Chip
            sx={{
              pl: "4px",
              pr: "4px",
              backgroundColor: parseAutomationChip(automation.active),
              color: "#fff",
            }}
            size="small"
            label={parseAutomationStatus(automation.active)}
          ></Chip>
        </TableCell>
        <TableCell>
          <Button variant="contained" onClick={viewAutomation}>
            Edit
          </Button>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <Table
      aria-label="simple table"
      sx={{
        mt: 3,
        whiteSpace: "nowrap",
      }}
    >
      <TableHead>
        <TableRow>
          <TableCell>
            <Typography color="textSecondary" variant="h6">
              Smart View Name
            </Typography>
          </TableCell>
          <TableCell>
            <Typography color="textSecondary" variant="h6">
              Status
            </Typography>
          </TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {automations.map((automation, idx) => (
          <AutomationRow key={idx} automation={automation} />
        ))}
      </TableBody>
    </Table>
  );
};

export default AutomationTable;
