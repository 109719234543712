import React, { useEffect, useState } from "react";
import { AutomationsResponse } from "../types";
import { fetchAutomations } from "../api";
import ActiveAutomations from "../components/RunningAutomations";

import Typography from "@mui/material/Typography";
import { isProduction } from "../utils";
import LogRocket from "logrocket";
import AutomationTable from "../components/AutomationTable";
import { Box, Card, CardContent } from "@mui/material";

const Loading = () => {
  return <Typography>Loading...</Typography>;
};

const Automations = () => {
  const [userData, setUserData] = useState<null | AutomationsResponse>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchAutomations().then((response) => {
      if (response.ok) {
        response.json().then((data: AutomationsResponse) => {
          setUserData(data);
          setLoading(false);
        });
      } else {
        setLoading(false);
      }
    });
  }, []);

  if (isProduction() && userData) {
    LogRocket.identify(userData.userData.id.toString(), {
      email: userData.userData.email,
    });
  }

  if (loading) {
    return <Loading />;
  }

  if (!userData) {
    return <h1>No</h1>;
  }

  let component;
  if (!userData.closeData.closeApiKey) {
    component = <h2>Configure your Close API Key</h2>;
  } else {
    component = <AutomationTable automations={userData.automations} />;
  }

  return (
    <>
      <ActiveAutomations automations={userData.automations} />
      <Card variant="outlined">
        <CardContent>
          <Box
            sx={{
              display: {
                sm: "flex",
                xs: "block",
              },
              alignItems: "flex-start",
            }}
          >
            <Box>
              <Typography
                variant="h3"
                sx={{
                  marginBottom: "0",
                }}
                gutterBottom
              >
                Smart View SMS Automation
              </Typography>
            </Box>

            <Box
              sx={{
                marginLeft: "auto",
                mt: {
                  lg: 0,
                  xs: 2,
                },
              }}
            ></Box>
          </Box>
          <Box
            sx={{
              overflow: "auto",
              mt: 3,
            }}
          >
            {component}
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default Automations;
