import { TOKEN_KEY } from "./constants";
import { RunningStatus } from "./types";

export const makeRange = (start: number, end: number): number[] => {
  const ans = [];
  for (let i = start; i <= end; i++) {
    ans.push(i);
  }
  return ans;
};

export const saveToken = (token: string) => {
  localStorage.setItem(TOKEN_KEY, token);
};

export const fetchToken = () => {
  return localStorage.getItem(TOKEN_KEY);
};

export const deleteToken = () => {
  localStorage.removeItem(TOKEN_KEY);
};

export const isAuthenticated = () => {
  return !!fetchToken();
};

export const isProduction = () => {
  return process.env.REACT_APP_VERCEL_ENV === "production";
};

export const parseAutomationStatus = (status: boolean) => {
  return status ? RunningStatus.Running : RunningStatus.Stopped;
};

export const parseAutomationChip = (status: boolean) => {
  return status ? "primary.main" : "error.main";
};
