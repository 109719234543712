import Snackbar from "@mui/material/Snackbar";
import React from "react";
import { Alert, AlertColor } from "@mui/material";
import { AlertStatus } from "../types";

interface Props {
  open: boolean;
  handleClose: (event: React.SyntheticEvent | Event, reason?: string) => void;
  status: string;
  item: string;
}

const ConfigurationSnackbar = ({ open, handleClose, status, item }: Props) => {
  let message;
  if (status === AlertStatus.SUCCESS) {
    message = `${item} updated successfully`;
  } else {
    message = `${item} was not updated successfully`;
  }
  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <Alert
        onClose={handleClose}
        severity={status ? (status as AlertColor) : undefined}
        sx={{ width: "100%" }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default ConfigurationSnackbar;
