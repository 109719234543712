import React from "react";
import "./App.css";

import { Routes, Route, Navigate } from "react-router-dom";

import SignUp from "./screens/SignUp";
import SignIn from "./screens/SignIn";
import Automations from "./screens/Automations";
import Configuration from "./screens/Configuration";
import { PATHS } from "./constants";
import Automation from "./screens/Automation";
import Payment from "./screens/Payment";
import { isAuthenticated, isProduction } from "./utils";
import LogRocket from "logrocket";
import { ThemeProvider } from "@mui/material/styles";
// @ts-ignore
import { baseTheme } from "./assets/global/Theme-variable";
import FullLayout from "./layouts/FullLayout/FullLayout";

const App = () => {
  if (isProduction()) {
    LogRocket.init("mlzvtx/triton-rei-collective");
  }

  const theme = baseTheme;

  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route element={<FullLayout />}>
          <Route path={PATHS.signIn} element={<SignIn />} />
          <Route path={PATHS.signUp} element={<SignUp />} />
          <Route
            path={PATHS.configuration}
            element={
              <ProtectedRoute>
                <Configuration />
              </ProtectedRoute>
            }
          />
          <Route
            path={PATHS.home}
            index
            element={
              <ProtectedRoute>
                <Automations />
              </ProtectedRoute>
            }
          />
          <Route
            path={PATHS.automation}
            element={
              <ProtectedRoute>
                <Automation />
              </ProtectedRoute>
            }
          />
          <Route
            path={PATHS.payment}
            element={
              <ProtectedRoute>
                <Payment />
              </ProtectedRoute>
            }
          />
          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>
    </ThemeProvider>
  );
};

export default App;

const NoMatch = () => {
  return <p>There's nothing here: 404!</p>;
};

const ProtectedRoute = ({ children }: { children: any }) => {
  if (!isAuthenticated()) {
    return <Navigate to={PATHS.signIn} replace />;
  }

  return children;
};
