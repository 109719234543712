import { CreditCard, DashboardOutlined, Settings } from "@mui/icons-material";
import { PATHS } from "../../../constants";

const MenuItems = [
  {
    title: "Automations",
    icon: DashboardOutlined,
    href: PATHS.home,
  },
  {
    title: "Configuration",
    icon: Settings,
    href: PATHS.configuration,
  },
  {
    title: "Payments",
    icon: CreditCard,
    href: PATHS.payment,
  },
];

export default MenuItems;
