import { Card, CardContent, Chip, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";
import { AutomationType } from "../types";
import { parseAutomationChip } from "../utils";

const RunningAutomations = ({
  automations,
}: {
  automations: AutomationType[];
}) => {
  const runningCount = automations.filter(
    (automation) => automation.active
  ).length;
  const stoppedCount = automations.length - runningCount;

  return (
    <Grid container direction="row" alignItems="center" justifyContent="center">
      <Grid item>
        <Card variant="outlined">
          <CardContent>
            <Typography
              color="primary"
              variant="h3"
            >{`Running Automations: ${runningCount}`}</Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item>
        <Card variant="outlined">
          <CardContent>
            <Typography
              color="error"
              variant="h3"
            >{`Stopped Automations: ${stoppedCount}`}</Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default RunningAutomations;
