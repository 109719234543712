import React, { useEffect } from "react";
import { fetchConfiguration, updateConfiguration } from "../api";

import { AlertStatus, ConfigurationResponse } from "../types";
import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useForm, SubmitHandler } from "react-hook-form";
import ConfigurationSnackbar from "../components/ConfigurationSnackbar";

type Inputs = {
  closeApiKey: string;
};

const Configuration = () => {
  const { register, handleSubmit, setValue } = useForm<Inputs>();
  const [open, setOpen] = React.useState(false);
  const [status, setStatus] = React.useState<"" | AlertStatus>("");

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
    setStatus("");
  };

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    updateConfiguration(data).then((response) => {
      if (response.ok) {
        setOpen(true);
        setStatus(AlertStatus.SUCCESS);
      } else {
        setOpen(true);
        setStatus(AlertStatus.ERROR);
      }
    });
  };

  useEffect(() => {
    fetchConfiguration().then((response) => {
      if (response.ok) {
        response
          .json()
          .then((data: ConfigurationResponse) =>
            setValue("closeApiKey", data.closeApiKey)
          );
      }
    });
  }, [setValue]);

  return (
    <Container component="main" maxWidth="sm">
      <Box
        sx={{
          marginTop: 8,
          flexDirection: "column",
        }}
      >
        <Typography align="center" component="h1" variant="h5">
          Configure Close
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          sx={{ mt: 1 }}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            id="closeApiKey"
            label="Close API Key"
            variant="filled"
            InputLabelProps={{ shrink: true }}
            {...register("closeApiKey")}
          />
          <Box alignItems="center" justifyContent="center" display="flex">
            <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>
              Save
            </Button>
          </Box>
        </Box>
      </Box>

      <ConfigurationSnackbar
        open={open}
        handleClose={handleClose}
        status={status}
        item={"Configuration"}
      />
    </Container>
  );
};

export default Configuration;
