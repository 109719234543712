import {
  ConfigurationRequest,
  EditAutomationPayload,
  UserSignIn,
  UserSignUp,
} from "./types";
import { ENDPOINTS } from "./constants";
import { fetchToken } from "./utils";

let url = "http://127.0.0.1:8000";

if (process.env.REACT_APP_VERCEL_ENV === "production") {
  url = "https://triton-be.herokuapp.com";
}

export const authenticatedHeaders = () => {
  return {
    "Content-Type": "application/json",
    Authorization: `Token ${fetchToken()}`,
  };
};

export const fetchAutomations = () => {
  return fetch(`${url}${ENDPOINTS.automations}`, {
    headers: authenticatedHeaders(),
  });
};

export const signUp = async (data: UserSignUp) => {
  return await fetch(`${url}${ENDPOINTS.signUp}`, {
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify(data),
  });
};

export const signIn = async (data: UserSignIn) => {
  return await fetch(`${url}${ENDPOINTS.login}`, {
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify(data),
  });
};

export const fetchConfiguration = async () => {
  return fetch(`${url}${ENDPOINTS.configuration}`, {
    headers: authenticatedHeaders(),
  });
};

export const updateConfiguration = async (data: ConfigurationRequest) => {
  return await fetch(`${url}${ENDPOINTS.configuration}`, {
    headers: authenticatedHeaders(),
    method: "POST",
    body: JSON.stringify(data),
  });
};

export const updateAutomation = async (
  automationId: string,
  data: EditAutomationPayload
) => {
  return await fetch(`${url}${ENDPOINTS.automation}${automationId}`, {
    headers: authenticatedHeaders(),
    method: "PUT",
    body: JSON.stringify(data),
  });
};

export const toggleAutomation = async (
  automationId: string,
  active: boolean
) => {
  return await fetch(`${url}${ENDPOINTS.automation}${automationId}`, {
    headers: authenticatedHeaders(),
    method: "PUT",
    body: JSON.stringify({ active: !active }),
  });
};

export const fetchAutomation = async (automationId: string) => {
  return fetch(`${url}${ENDPOINTS.automation}${automationId}`, {
    headers: authenticatedHeaders(),
  });
};

export const paymentUrl = `${url}${ENDPOINTS.checkoutSession}`;

export const createCheckoutSession = async () => {
  return fetch(`${url}${ENDPOINTS.checkoutSession}`, {
    headers: authenticatedHeaders(),
    method: "POST",
  });
};

export const fetchSubscriptionStatus = async () => {
  return fetch(`${url}${ENDPOINTS.subscriptionStatus}`, {
    headers: authenticatedHeaders(),
  });
};
