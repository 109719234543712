import React from "react";

import { AppBar, IconButton, Toolbar } from "@mui/material";
import { MenuOutlined } from "@mui/icons-material";

interface Props {
  sx: {
    paddingLeft: string;
    backgroundColor: string;
  };
  toggleSidebar: () => void;
  toggleMobileSidebar: () => void;
}

const Header = (props: Props) => {
  return (
    <AppBar sx={props.sx} elevation={0}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="menu"
          onClick={props.toggleMobileSidebar}
          sx={{
            display: {
              lg: "none",
              xs: "inline",
            },
          }}
        >
          <MenuOutlined width="20" height="20" />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
